import { Group, Skeleton, Text, Tooltip } from '@mantine/core'
import { currencyFormatter } from '@ospace/common'

export const formattedAmount = (figure: number | undefined, isLoading: boolean) => {
  const { format } = currencyFormatter(true)
  return isLoading ? (
    <Skeleton visible={isLoading} height={16} width='20%' radius='md' />
  ) : (
    `${format(figure || 0)}`
  )
}

export const DisplayAmount = ({
  isLoading,
  label,
  amount,
  currency,
  tooltip,
}: {
  isLoading: boolean
  amount: number | undefined
  label: string
  currency: string
  tooltip: string | undefined
}) => (
  <div
    style={{
      backgroundColor: '#ebf5fe',
      flex: 1,
      padding: 10,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '0.5rem',
    }}
    key={label}
  >
    <Text>{label}</Text>

    <Group spacing='xs' align='baseline'>
      <Text fw={600} c={'#000'}>
        {currency}
      </Text>
      <Tooltip label={tooltip || ''} multiline>
        <Text fw={600} fz={30} c={'#000'}>
          {formattedAmount(amount, isLoading)}
        </Text>
      </Tooltip>
    </Group>
  </div>
)
